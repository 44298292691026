'use strict';

// Import Libraries
import React      from 'react';
import classNames from 'classnames';

// Import JS Modules
import { href, link_classes } from './utils';

const Dropdown = (props) => {
  const { sublinks } = props.links;

  let $sublinks = (sublinks || []).map((sublink, i) => {
    return (
      <a key={sublink.name + i}
         className={classNames('dropdown-item', link_classes(sublink))}
         href={href(sublink)}>
        { sublink.name }
      </a>
    );
  });

  return (
    <li className={classNames('nav-item dropdown', link_classes(props.links), {show: props.active, 'd-none': props.hidden})}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}>
      <a className="nav-link dropdown-toggle"
         href={href(props.links)}
         onClick={handleClick}>
        { props.links.name }
      </a>
      <div className={classNames('dropdown-menu', {'dropdown-menu-right': props.right, show: props.active, 'd-none': !sublinks.length })}>
        { $sublinks }
      </div>
    </li>
  );

  function handleClick(e) {
    if (href(props.links) == '#') {
      e.preventDefault();
    }
  }
};

export default Dropdown;
