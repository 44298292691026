'use strict';

// Import Libraries
import React         from 'react';
import classNames    from 'classnames';

// Import JS Modules
import FlyoutSection from './flyout-section'
import Wrapper       from './wrapper'
import { href, link_classes, megamenu_wrapper } from './utils';

const Flyout = (props) => {
  let $sublinks = (props.links.sublinks || []).map((sublink, i) => {
    if (megamenu_wrapper(sublink)) {
      return (
        <Wrapper key={sublink.name + i} section={sublink} />
      );
    }
    return (
      <li key={sublink.name + i}
          className={classNames("megamenu-sub-menu", link_classes(sublink) )} >
        <FlyoutSection section={sublink} />
      </li>
    );
  });

  // Handler to simulate mouse enter on key press
  const handleTopLevelKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      props.onMouseEnter();
    }
  };

  const handleFlyoutKeypress = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      props.onMouseLeave();
    }
  }

  return (
    <li className={classNames('nav-item dropdown mega', link_classes(props.links), {show: props.active, 'd-none': props.hidden})}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}>
      <a className="nav-link dropdown-toggle"
         href={href(props.links)}
         onKeyDown={handleTopLevelKeyPress}
         role="button"
         aria-haspopup="true"
         aria-expanded={props.active ? "true" : "false"}>
        {props.links.name}
      </a>
      <div className={classNames('dropdown-menu', {show: props.active})} style={{left: props.positionLeft, right: props.positionRight}}>
        <a className="link-title" href={href(props.links)}
          onKeyDown={handleFlyoutKeypress}
        >
          {props.links.name}
        </a>
        <ul className="list-unstyled columns"
          onKeyDown={handleFlyoutKeypress}>
          {$sublinks}
        </ul>
      </div>
    </li>
  );
}

export default Flyout;
